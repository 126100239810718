import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Alexandros Kazantzidis" />

    <div className="h-full w-full bg-slate-900">
      <div className="flex flex-col sm:flex-row">
        <div className="w-full sm:w-1/3 pt-16 pl-4 sm:pl-16 pr-4 sm:pr-0 cursor-default">
          <div className="block sm:sticky top-0 text-white">
            <h1 className="text-3xl font-semibold pt-8 text-center sm:text-start">
              Alexandros Kazantzidis
            </h1>

            <h2 className="mt-2 text-white text-xl text-center sm:text-start">
              6-year streak of making software do the happy dance!
            </h2>

            <p className="mt-8 text-gray-400 text-md">
              Who needs a plain old resume when you can join me on an adventure
              through the twists and turns of my professional timeline?
            </p>

            <div className="mt-8">
              <h3 className="text-sm ml-2.5">Find me also here</h3>

              <div className="">
                <a
                  href="https://www.linkedin.com/in/alexandros-kazantzidis/"
                  target="_blank"
                >
                  <StaticImage
                    className="scale-75"
                    src="../images/linkedin.png"
                    alt="linkedin"
                  />
                </a>

                <a
                  className=""
                  href="https://github.com/Alexandros23Kazantzidis"
                  target="_blank"
                >
                  <StaticImage
                    className="bg-white rounded-xl scale-50"
                    src="../images/github.png"
                    alt="github"
                  />
                </a>
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-teal-400 font-semibold">
                Cool links section
              </h3>

              <a
                className="flex items-center mt-4 ml-4"
                href="/job-rider"
                target="_self"
              >
                <StaticImage
                  className="w-6 h-6"
                  src="../images/cool_link.png"
                  alt="link"
                />

                <p className="ml-2 text-sm text-gray-400">
                  Job Rider deep dive
                </p>
              </a>

              <a
                className="flex items-center mt-4 ml-4"
                href="/orbit"
                target="_self"
              >
                <StaticImage
                  className="w-6 h-6"
                  src="../images/cool_link.png"
                  alt="link"
                />

                <p className="ml-2 text-sm text-gray-400">
                  Orbit Determinator visualization
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full sm:w-2/3 py-16 pr-8 cursor-default">
          <div className="pl-8 sm:pl-16 mt-8">
            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">September 2011</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Aristotle University of Thessaloniki Engineering school
                </h3>
                <p className="mt-4 text-gray-200">
                  Embarked on a wild ride at the Engineering school in my
                  hometown, where I geeked out over
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Programming
                  </span>
                  ,
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Space Geodesy
                  </span>
                  , and decoding the mysteries of
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Geospatial data
                  </span>
                  . 🌍
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="https://www.auth.gr/en/faculty/eng-en/"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      Aristotle Uni
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">
                  November 2016 & April 2017
                </p>
                <h3 className="text-xl mt-1 font-semibold">
                  Student competitions
                </h3>
                <p className="mt-4 text-gray-200">
                  Zooming through the galaxy, I clinched the 2nd prize in a
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    European Space Geodesy challenge
                  </span>
                  . Teaming up with two cosmic pals, we bagged another prize at
                  the
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    NASA Space Apps Hackathon
                  </span>{" "}
                  in Thessaloniki. 🏆
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="http://www.egsiem.eu/120-winners-of-the-egsiem-student-challenge-2016"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      EGSIEM Challenge
                    </p>
                  </a>

                  <a
                    className="flex items-center"
                    href="https://2017.spaceappschallenge.org/challenges/planetary-blues/wheres-water/teams/poseidon"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      NASA Challenge
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">June 2017</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Finished my Engineering degree and Thesis
                </h3>
                <p className="mt-4 text-gray-200">
                  Closed the study chapter with a
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    thesis on Satellite orbit determination methods
                  </span>
                  . If my code were a movie, it would be in the 'so bad it's
                  good' category - no links, just laughs! 🛰️
                </p>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">
                  July 2017 - September 2017
                </p>
                <h3 className="text-xl mt-1 font-semibold">
                  Google Summer of code 2017
                </h3>
                <p className="mt-4 text-gray-200">
                  Initiated my coding journey with a pal, creating an
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    open-source Python package for Satellite orbit determination
                  </span>
                  . We added unit tests, crisp documentation, and threw it all
                  out there for the world to dive into! 🐍
                </p>

                <div className="flex">
                  <a className="flex items-center" href="/orbit" target="_self">
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      OrbitDeterminator
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">
                  October 2017 - February 2019
                </p>
                <h3 className="text-xl mt-1 font-semibold">
                  Master of Science in Geoinformatics
                </h3>
                <p className="mt-4 text-gray-200">
                  Diving deeper into the coding galaxy, I delved into extensive
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    geospatial analysis
                  </span>{" "}
                  and
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    satellite orbit determination for my second master's thesis
                  </span>
                  . Because why settle for one master when you can have two,
                  right? 📚
                </p>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">April 2018 - May 2018</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Data Science internship at Avoy
                </h3>
                <p className="mt-4 text-gray-200">
                  Spent 3 months on a remote internship with Avoy, the
                  travel-savvy wizards! My mission? Crafting a{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    data pipeline with Python
                  </span>{" "}
                  to keep the company's info flowing smoothly – because even
                  data deserves a vacation! ✈️
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="https://avoy.io/"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">Avoy.io</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-white rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">
                  June 2018 - September 2018
                </p>
                <h3 className="text-xl mt-1 font-semibold">
                  Mentor for Google summer of code 2018
                </h3>
                <p className="mt-4 text-gray-200">
                  Guided a dynamic duo of budding developers, navigating the
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    open-source
                  </span>{" "}
                  wilderness together. Steering them through the{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Python package for satellite orbit determination
                  </span>{" "}
                  that I kicked off – because coding is more fun when it's a
                  team sport! 👩‍💻
                </p>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-teal-400 rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">July 2018 - June 2021</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Fullstack Software engineer at Comidor
                </h3>
                <p className="mt-4 text-gray-200">
                  Dived headfirst into the world of full-time coding fun at
                  Comidor, a Greek hub for low-code BPM brilliance! Proud team
                  member of the crew that birthed the shiny new platform
                  version. Picture this:
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Vue.js, Java, MySQL
                  </span>
                  , mobile apps jazzed up with{" "}
                  <span className="text-teal-400 font-semibold"> Cordova</span>,
                  and a sprinkle of{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Machine Learning with Weka and Java
                  </span>
                  . Just another day at the coding playground! 💻
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="https://www.comidor.com/"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">Comidor</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-teal-400 rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">
                  June 2021 - December 2022
                </p>
                <h3 className="text-xl mt-1 font-semibold">
                  Frontend Software engineer at Everli
                </h3>
                <p className="mt-4 text-gray-200">
                  Embarked on an adventure with the Everli crew, the maestros of
                  grocery delivery in Italy. Armed with{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Vue.js, Nuxt, TypeScript
                  </span>
                  , and a touch of{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Jest and Cypress
                  </span>{" "}
                  magic, I'm on a quest to turn grocery shopping into a seamless
                  joyride for all! 🛒
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="https://about.everli.com/"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">Everli</p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-teal-400 rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">January 2023 - Present</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Internal switch to Fullstack engineer at Everli
                </h3>
                <p className="mt-4 text-gray-200">
                  Team SOS in the backend world, and guess who swooped in? Yours
                  truly, donning the{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    PHP and Laravel
                  </span>{" "}
                  cape to the rescue! Because when the team needs a hero, I'm
                  there to ensure success prevails! 🦸‍♂️
                </p>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-teal-400 rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">May 2023</p>
                <h3 className="text-xl mt-1 font-semibold">
                  Job Rider side project started
                </h3>
                <p className="mt-4 text-gray-200">
                  Launched Job Rider, my brainchild side project, a mobile app
                  jazzing up commutes with Carpooling vibes for work warriors.
                  Picture this:{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    React Native
                  </span>{" "}
                  weaving its magic for the Android app, and{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    Supabase
                  </span>{" "}
                  holding down the fort in the backend along with{" "}
                  <span className="text-teal-400 font-semibold">
                    {" "}
                    PostgreSQL and PostGIS
                  </span>
                  . Oh, and did I mention Job Rider snagged a prize at a Social
                  Hackathon in Greece? Because why not add a cherry on top of
                  the code! 🚗
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="/job-rider"
                    target="_self"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      Job Rider
                    </p>
                  </a>

                  <a
                    className="flex items-center"
                    href="https://www.cnn.gr/oikonomia/epixeiriseis/story/397122/social-hackathon-enas-telikos-me-polloys-nikites-ola-osa-ksexorisame"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      Social Hackathon 2023
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <div className="border-l border-white h-fit pb-32 sm:pb-0 sm:h-[350px] flex items-center relative">
              <div className="h-6 w-6 bg-teal-400 rounded-full absolute left-[-12px]" />

              <div className="text-white ml-8 sm:ml-16">
                <p className="text-sm text-gray-500">January 2024</p>
                <h3 className="text-xl mt-1 font-semibold">
                  DevJournal.ai another side project on the way
                </h3>
                <p className="mt-4 text-gray-200">
                  Meet DevJournal.ai, my freshest sidekick! It's not just a{" "}
                  <span className="text-teal-400 font-semibold"> Next.js</span>{" "}
                  web app; it's the secret sauce for developers to effortlessly
                  log their coding escapades. Quick daily entries, an AI-powered
                  recap, and voila! Track your weeks, feel the coding pride.
                  Because every keystroke deserves a celebration! 🚀
                </p>

                <div className="flex">
                  <a
                    className="flex items-center"
                    href="https://devjournaling.com/"
                    target="_blank"
                  >
                    <StaticImage
                      className="scale-[0.3]"
                      src="../images/link.png"
                      alt="link"
                    />

                    <p className="ml-[-14px] text-sm text-gray-400">
                      DevJournal.ai
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p><Link to="/page-2/">Go to page 2</Link></p>
    <p><Link to="/using-typescript/">Go to "Using TypeScript"</Link></p> */}
  </Layout>
)

export default IndexPage
